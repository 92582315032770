<template>
  <div class="organizations-page pt-6">
    <CyNotification
      v-if="isMaxMembersReached"
      theme="warning"
      :title="$t('licence.reachedMaxUsersCapacity')"
      :content="$t('licence.cannotCreateOrganization')"
      :button-label="$t('licence.contactYourAdmin')"
      @click="navigateToContactAdmin"/>

    <CyNotification
      theme="error"
      :content="licenceErrors"/>

    <div
      v-show="showCreateOrgMessage"
      class="info-box mb-12">
      <span class="info-message">{{ $t('createFirstOrganization', { createOrgButton: $t('createOrg') }) }}</span>
    </div>
    <div class="orgs-top-bar mb-4 pl-14">
      <div class="current-location d-flex">
        <div class="current-organization d-flex flex-column">
          <span class="current-organization__label">{{ $t('currentOrganization') }}:</span>
          <span class="current-organization__value">{{ orgName || '-' }}</span>
        </div>
        <v-divider
          vertical
          class="ml-10 mr-6"/>
        <div class="current-role d-flex flex-column">
          <span class="current-role__label">{{ $t('currentRole') }}:</span>
          <span class="current-role__value">{{ userOrganizationRole || '-' }}</span>
        </div>
        <v-divider
          vertical
          class="ml-10 mr-6"/>
      </div>
      <portal to="header-actions">
        <CyNewOrganization/>
      </portal>
    </div>
    <div
      v-if="gotOrgList && !_.isEmpty(organizations)"
      class="orgs-list">
      <div class="orgs-list__actions d-flex flex-column space-y-10 mr-4">
        <CyTooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <CyButton
                icon-only
                icon="domain"
                theme="primary"
                :variant="!currentScopeDepth ? 'primary' : 'tertiary'"
                :elevation="!currentScopeDepth ? 0 : 1"
                :disabled="!currentScopeDepth"
                @click="goToOrigin"/>
            </span>
          </template>
          <span>{{ $t('backToOrigin') }}</span>
        </CyTooltip>
        <CyTooltip top>
          <template #activator="{ on }">
            <span v-on="on">
              <CyButton
                icon-only
                icon="arrow_back"
                theme="primary"
                :variant="!currentScopeDepth ? 'primary' : 'tertiary'"
                :elevation="!currentScopeDepth ? 0 : 1"
                :disabled="!currentScopeDepth"
                @click="goBackOneLevel"/>
            </span>
          </template>
          <span>{{ $t('backOneLevel') }}</span>
        </CyTooltip>
      </div>
      <div class="orgs-list__items space-x-4 cy-scrollbars">
        <CyOrganizationsListPanel
          v-show="isColumnDisplayed(scope)"
          v-for="(scope, level) in [...currentScopes, {}]"
          ref="orgsListItem"
          :key="`level-${level}`"
          :level="parseInt(level, 10)"
          :parent-scope="level ? currentScopes[level - 1] : null"
          @focus-org="shiftToColumn(level && level - 1)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { constructBreadcrumb, decodeJWT } from '@/utils/helpers'
import CyOrganizationsListPanel from '@/components/organizations-list-panel'
import CyNewOrganization from '@/components/new-organization.vue'

export default {
  name: 'CyPageOrganizations',
  components: {
    CyOrganizationsListPanel,
    CyNewOrganization,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.organizations'))
  },
  header () {
    return {
      title: this.$t('Organizations'),
      description: {
        text: this.$t('headerDescription'),
      },
    }
  },
  data: () => ({
    loading: true,
    gotOrgList: false,
  }),
  computed: {
    ...mapState({
      currentScopes: (state) => Object.values(state.customers.scopes),
      currentScopeDepth: (state) => state.customers.currentScopeDepth,
      licence: (state) => state.organization.licence.detail,
      licenceErrors: (state) => state.organization.licence.errors,
      organizations: (state) => state.organizations,
    }),
    ...mapGetters('organization/licence', [
      'isMaxMembersReached',
    ]),
    ...mapGetters('auth', [
      'jwtDecoded',
    ]),
    userOrganizationRole () {
      return _.get(this.jwtDecoded, 'role', false)
    },
    showCreateOrgMessage () {
      return !this.loading && _.isEmpty(this.organizations)
    },
  },
  async mounted () {
    await this.fetchOrgsList()
    this.shiftToColumn(this.currentScopeDepth)
  },
  methods: {
    ...mapActions('organization', [
      'CHANGE_ORGANIZATION',
      'FETCH_AVAILABLE',
    ]),
    ...mapActions('customers', [
      'LOGIN_AS_PARENT',
    ]),
    isColumnDisplayed (scope) {
      if (scope.canonical) return true

      const scopeJWT = _.get(_.last(this.currentScopes), 'jwt')
      if (!scopeJWT) return false

      const decodedJWT = decodeJWT(scopeJWT.split('.')[1])
      const scopeOrganization = _.get(decodedJWT, 'cycloid.organization', {})
      return !!scopeOrganization.has_children
    },
    navigateToContactAdmin () {
      window.location.assign(`mailto:${this.licence?.email_address}`)
    },
    async shiftToColumn (targetColumnIndex) {
      const targetColumn = _.get(this.$refs, `orgsListItem[${targetColumnIndex}].$el`)
      if (targetColumn?.scrollIntoView) targetColumn.scrollIntoView({ behavior: 'smooth', inline: 'start' })
    },
    goToOrigin () {
      const { canonical: nextCanonical, name } = _.get(this.currentScopes, 0)
      this.CHANGE_ORGANIZATION({ nextCanonical, name })
      this.shiftToColumn(0)
    },
    goBackOneLevel () {
      const { canonical } = _.get(this.currentScopes, this.currentScopeDepth - 1)
      this.LOGIN_AS_PARENT({ canonical })
      this.shiftToColumn(this.currentScopeDepth - 1)
    },
    async fetchOrgsList () {
      this.loading = true
      await this.FETCH_AVAILABLE({ keyPath: 'organizations' })
      this.gotOrgList = true
      this.loading = false
    },
  },
  i18n: {
    messages: {
      en: {
        backOneLevel: 'Back one level',
        backToOrigin: 'Back to origin',
        createFirstOrganization: `It looks like you don't have an organization yet! Click on the '{createOrgButton}' button below to get started.`,
        createOrg: 'Create a new organization',
        currentOrganization: 'Current organization',
        currentRole: 'Current @:Role',
        headerDescription: 'Organizations are independent workspaces where your teams can collaborate across multiple projects.',
        leave: 'leave',
        title: '@:routes.organizations',
      },
      es: {
        backOneLevel: 'Sube un nivel',
        backToOrigin: 'De vuelta al origen',
        createFirstOrganization: `Parece que aun no tienes ninguna organización! Pulsa en el botón '{createOrgButton}' para empezar.`,
        createOrg: 'Crear una nueva organización',
        currentOrganization: 'Organización actual',
        currentRole: '@:Role actual',
        headerDescription: 'Las organizaciones son espacios de trabajo independientes donde sus equipos pueden colaborar en múltiples proyectos.',
        leave: 'salir',
        title: '@:routes.organizations',
      },
      fr: {
        backOneLevel: `Remonter d'un niveau`,
        backToOrigin: `Retour à l'origine`,
        createFirstOrganization: `Il semblerait que vous n'ayez pas encore d'organisation! Cliquez sur le bouton '{createOrgButton}' ci dessous.`,
        createOrg: 'Créer une nouvelle organisation',
        currentOrganization: 'Organisation actuelle',
        currentRole: '@:Role actuel',
        headerDescription: 'Les organisations sont des espaces de travail indépendants où vos équipes peuvent collaborer sur plusieurs projets.',
        leave: 'leave',
        title: '@:routes.organizations',
      },
    },
  },

}
</script>

<style lang="scss" scoped>
.organizations-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 112px);
  overflow-y: hidden;

  .cy-table-cmp ::v-deep td.is-selected:first-child {
    padding-left: 30px !important;
    border-left: 8px solid get-color("secondary");
  }

  .info {
    &-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 4px auto;
      padding: 16px;
      border: 1px dashed !important;
      border-radius: 5px;
      border-color: get-color("info");
      color: get-color("info");
      font-size: 14px;
    }

    &-message {
      color: get-color("primary");
    }
  }

  .current-location {
    .current {
      &-organization,
      &-role {
        &__label {
          color: get-color("primary", "light-2");
        }

        &__value {
          color: get-color("primary");
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  .orgs-list {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    width: 100%;
    overflow-y: hidden;

    &__items {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 2px;
      overflow-y: auto;
    }

    &__actions {
      .v-btn {
        background: white;
        color: get-color("primary");

        &:hover {
          background: get-color("primary", "light-4");
        }

        &:active,
        &:focus {
          background: get-color("primary", "light-3");
        }
      }
    }
  }

  .orgs-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px;
  }
}
</style>

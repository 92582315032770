<template>
  <div>
    <CyButton
      variant="primary"
      theme="secondary"
      icon="add"
      :disabled="isMaxMembersReached"
      @click="handleOpenModal">
      {{ $t('createOrg') }}
    </CyButton>
    <CyFormsAddOrganization
      v-model="isOpen"
      @close="closeModal"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gtmOrganizationsEvents } from '@/utils/helpers/analytics'
import CyFormsAddOrganization from '@/components/forms/add-organization.vue'

export default {
  name: 'CyNewOrganization',
  components: {
    CyFormsAddOrganization,
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapGetters('organization/licence', [
      'isMaxMembersReached',
    ]),
  },
  methods: {
    handleOpenModal () {
      this.isOpen = true
      this.$gtm.trackEvent(gtmOrganizationsEvents.organizationsCreateNewOrganization)
    },
    closeModal () {
      this.isOpen = false
    },
  },
  i18n: {
    messages: {
      en: {
        createOrg: 'Create a new organization',
      },
      es: {
        createOrg: 'Crear una nueva organización',
      },
      fr: {
        createOrg: 'Créer une nouvelle organisation',
      },
    },
  },
}
</script>

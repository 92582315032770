<template>
  <div class="orgs-list__panel">
    <div class="orgs-list__header pa-4">
      <v-icon
        v-if="!searchActive"
        class="search-toggle"
        @click="$toggle.searchActive">
        search
      </v-icon>
      <transition
        name="slide-fade-left"
        mode="out-in">
        <v-col
          v-if="searchActive"
          key="search"
          class="pa-0">
          <CySearchBox
            v-model.trim="search"
            append-icon="search"
            autofocus
            clearable
            class="search-field pt-0 mt-0"
            hide-details
            @click:clear="search = ''; $toggle.searchActive(false)"
            @blur="$toggle.searchActive(search.length ? true : false)"/>
        </v-col>
      </transition>
      <transition
        name="slide-fade-right"
        mode="out-in">
        <div
          v-if="!searchActive"
          class="d-flex">
          <v-divider
            class="ml-3 mr-6"
            vertical/>
          <div class="panel-scope d-flex flex-column">
            <span class="panel-scope__label">{{ $t('selected') }}:</span>
            <span class="panel-scope__value">{{ _.get(currentScope, 'name', '-') }}</span>
          </div>
        </div>
      </transition>
    </div>
    <div class="orgs-list__items cy-scrollbars">
      <div
        v-if="_.isEmpty(sortOrgs(filteredOrgs)) && search.length"
        class="orgs-list__empty pa-4">
        {{ $t('noMatchFound') }}
      </div>
      <CyOrganizationsListItem
        v-for="org of sortOrgs(filteredOrgs)"
        :key="org.canonical"
        :org="org"
        :level="level"
        :is-selected="isSelected({ canonical: org.canonical })"
        @focusOrg="$emit('focus-org')"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { decodeJWT } from '@/utils/helpers'
import CyOrganizationsListItem from '@/components/organizations-list-item'
import CySearchBox from '@/components/search-box.vue'

export default {
  name: 'CyOrganizationsListPanel',
  components: {
    CyOrganizationsListItem,
    CySearchBox,
  },
  props: {
    level: {
      type: Number,
      required: true,
    },
    parentScope: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    organizations: [],
    searchActive: false,
    search: '',
  }),
  computed: {
    ...mapState({
      scopes: (state) => state.customers.scopes,
      rootOrganizations: (state) => state.organizations,
      jwt: (state) => state.auth.jwt,
      permissions: (state) => state.auth.permissions,
      owns: (state) => state.auth.owns,
    }),
    currentScope () {
      return _.get(this.scopes, this.level, null)
    },
    filteredOrgs () {
      return this.search
        ? this.organizations.filter(({ canonical, name }) => name.toLowerCase().includes(this.search.toLowerCase()) || canonical === this.orgCanonical)
        : this.organizations
    },
  },
  watch: {
    parentScope (newValue, oldValue) {
      if (_.get(newValue, 'canonical') !== _.get(oldValue, 'canonical')) {
        this.getOrgs()
      }
    },
  },
  mounted () {
    this.getOrgs()
  },
  methods: {
    ...mapMutations('auth', [
      'SET_CREDENTIALS',
    ]),
    async getOrgs () {
      const isRootLevel = this.level === 0
      const hasParent = this.parentScope?.canonical

      if (isRootLevel) {
        this.organizations = this.rootOrganizations
        return
      }

      if (hasParent) await this.getChildrenOfParent(this.parentScope)
      else this.organizations = []
    },
    async getChildrenOfParent ({ jwt, canonical }) {
      const jwtToken = this.jwt
      this.setCredentials(jwt)

      const { data = [] } = await this.$cycloid.ydAPI.getOrgChildren(canonical) || {}
      this.organizations = data

      this.setCredentials(jwtToken)
    },
    setCredentials (token) {
      const { permissions, owns } = this
      const jwtCanonical = this.jwt ? decodeJWT(this.jwt.split('.')[1])?.cycloid?.organization?.canonical : null
      const isTokenChanging = this.parentScope.canonical !== jwtCanonical
      if (isTokenChanging) this.SET_CREDENTIALS({ token, permissions, owns })
    },
    sortOrgs (orgs) {
      const isBlocked = ({ blocked }) => !!blocked.length
      const caseInsensitiveName = ({ name }) => name.toLowerCase()
      return _.orderBy(orgs, [this.isSelected, isBlocked, 'has_children', caseInsensitiveName], ['desc', 'asc', 'desc', 'asc'])
    },
    isSelected ({ canonical }) {
      return _.isEqual(canonical, this.currentScope?.canonical)
    },
  },
  i18n: {
    messages: {
      en: {
        noMatchFound: 'No match found',
        selected: 'Selected',
      },
      es: {
        noMatchFound: 'Ningún resultado encontrado',
        selected: 'Eligidas',
      },
      fr: {
        noMatchFound: 'Aucun résultat',
        selected: 'Choisies',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .orgs-list {
    &__panel {
      display: flex;
      flex-basis: calc(33.3% - 10px);
      flex-direction: column;
      flex-shrink: 0;
      border-radius: 4px;
      background-color: white;
      box-shadow:
        0 1px 5px get-color("primary", $alpha: 0.2),
        0 1px 1px get-color("primary", $alpha: 0.14),
        0 1px 3px get-color("primary", $alpha: 0.12);
    }

    &__header {
      display: flex;
      flex-shrink: 0;
      height: 70px;
      border-bottom: 2px solid map.get($grey, "light-3");

      .search-toggle {
        width: 36px;
      }

      .panel-scope {
        font-size: map.get($font-sizes, "sm");

        &__label {
          color: get-color("primary", "light-2");
        }

        &__value {
          color: get-color("primary");
          font-weight: $font-weight-bold;
          text-transform: uppercase;
        }
      }
    }

    &__empty {
      color: get-color("grey", "dark-2");
    }

    &__items {
      position: relative;
      height: 100%;
    }
  }

  .pin-to-top {
    position: sticky;
    top: 0;
  }

  .slide-fade-left {
    &-enter-active {
      transform-origin: left center;
      transition: all 0.3s ease-in;
      animation: slide-in 0.3s;
    }

    &-enter {
      transform: translateX(-20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
      opacity: 0;
    }
  }

  .slide-fade-right {
    &-enter-active {
      transform-origin: right center;
      transition: all 0.45s ease;
    }

    &-enter {
      transform: translateX(20px) !important;
      opacity: 0;
    }

    &-leave,
    &-leave-active {
      transition: none;
      opacity: 0;
    }
  }
</style>
